import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import Loader from "../layout/loader";
import BannerContatctUs from "./BannerContactUs";
// import acrossGlobe from "../../assets/img/greenHydroImages//homePageBottomleft.png";
import ban from "../../assets/img/greenHydroImages/earth-core-structure.png";
import rightimg from "../../assets/img/greenHydroImages/green-forest.jpg";
import methanepyro from "../../assets/img/greenHydroImages/Picture1.jpg";
import methanolImage from "../../assets/img/methanol.jpg";
import icon1 from "../../assets/icons/icon1.png"; // Replace with your actual icon paths
import icon2 from "../../assets/icons/icon2.png";
import icon3 from "../../assets/icons/icon3.png";

// Concept Section Component
const ConceptSection = () => {
  return (
    <div style={{ padding: "80px 0 0 0", backgroundColor: "#fff" }}>
      {" "}
      {/* Increased padding */}
      <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "60px",
            fontSize: "36px",
            fontWeight: "bold",
          }}
        >
          The Concept
        </h2>{" "}
        {/* Increased font size */}
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          {" "}
          {/* Increased gap between blocks */}
          {/* First Block */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f1f5f9",
              borderRadius: "15px" /* Increased border radius */,
              padding: "30px" /* Increased padding */,
              justifyContent: "center",
            }}
          >
            <img
              src={icon1}
              alt="Icon 1"
              style={{
                width: "60px",
                height: "60px",
                marginRight: "30px",
              }} /* Increased icon size */
            />
            <p style={{ marginBottom: 0, fontSize: "18px", lineHeight: "1.8" }}>
              {" "}
              {/* Increased font size and line height */}
              Geothermal power offers a continuous and reliable source of
              emissions-free power, without the intermittency of wind and solar
              power.
            </p>
          </div>
          {/* Second Block */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f1f5f9",
              borderRadius: "15px",
              padding: "30px",
              justifyContent: "center",
            }}
          >
            <img
              src={icon2}
              alt="Icon 2"
              style={{ width: "60px", height: "60px", marginRight: "30px" }}
            />
            <p style={{ marginBottom: 0, fontSize: "18px", lineHeight: "1.8" }}>
              The hot brines associated with sedimentary geothermal resources
              also include associated methane and CO2 which can be separated and
              used.
            </p>
          </div>
          {/* Third Block */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f1f5f9",
              borderRadius: "15px",
              padding: "30px",
              justifyContent: "center",
            }}
          >
            <img
              src={icon3}
              alt="Icon 3"
              style={{ width: "60px", height: "60px", marginRight: "30px" }}
            />
            <p style={{ marginBottom: 0, fontSize: "18px", lineHeight: "1.8" }}>
              These are the precursors to producing low-carbon hydrogen and
              high-value, low-emissions e-methanol, green gasoline and
              sustainable aviation fuel.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Dashboard Page Component
const DashboardPage = React.memo(() => {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init();
  }, []);

  const LearnMore = useCallback(() => navigate("/why-us"), [navigate]);
  const lets = useCallback(() => navigate("/contact-us"), [navigate]);

  return (
    <>
      <Loader />
      <BannerContatctUs onLearnMore={LearnMore} onLets={lets} />
      <div className="main-body">
        {/* Add ConceptSection here before the Renewable Power + Emissions-Free Methane section */}
        <ConceptSection />

        {/* Existing content */}
        <div className="future-text-wrap">
          <div className="banner_head">
            <div className="row gx-5 gy-5 future-statement">
              <div className="col-xl-6 col-sm-12 col-12 d-flex align-items-center">
                <div className="text_right">
                  <h5
                    data-aos-duration="700"
                    data-aos="fade-right"
                    data-aos-delay="1000"
                  >
                    Renewable Power + Emissions-Free Methane = Clean Hydrogen
                  </h5>
                  <p
                    data-aos-duration="700"
                    data-aos="fade-left"
                    data-aos-delay="700"
                    className="mt-2 mb-2"
                  >
                    GeoFuels has been formed to take clean power generated at
                    sedimentary geothermal power facilities developed by GT
                    Titan, an affiliated company. The hot brines that are
                    brought to the surface to produce power also contain methane
                    gas in solution. We separate that methane and put it through
                    a process called methane pyrolysis.
                  </p>
                  <div className="home-text-box">
                    <p>
                      Methane pyrolysis cracks methane into hydrogen and solid
                      carbon. Unlike burning or more conventional hydrogen
                      production pathways, there is no CO2 produced because the
                      reaction occurs in the absence of oxygen.
                    </p>
                  </div>
                  <br /> <br />
                  <a onClick={() => navigate("about/")} className="learn-more">
                    Know more
                  </a>
                </div>
              </div>

              {/* Image Section */}
              <div className="col-xl-6 col-sm-12 col-12">
                <div
                  data-aos-duration="700"
                  data-aos="fade-up"
                  data-aos-delay="1000"
                >
                  <div className="d-flex justify-content-end">
                    <img
                      src={rightimg}
                      alt=""
                      className="img-fluid"
                      style={{ maxWidth: "80%", height: "auto" }} // Adjusted max-width and height
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Additional sections */}
        {/* Methane Pyrolysis Section */}
        <div className="power-generation-info-wrap my-5">
          <div className="box_content">
            <div className="row gx-4 gy-4">
              {/* Image Section */}
              <div
                data-aos-duration="700"
                data-aos="fade-right"
                className="col-xl-6 col-sm-12 col-12"
              >
                <img
                  src={methanepyro}
                  className="bottom-dashbord-img img-fluid"
                  alt=""
                  style={{
                    marginBottom: "auto",
                    width: "90%",
                    // maxWidth: "100%",
                    height: "auto",
                    // paddingRight: "24px",
                  }} // Added margin-bottom for vertical spacing
                />
              </div>

              {/* Text Section */}
              <div className="col-xl-6 col-sm-12 col-12 d-flex align-items-center">
                <div
                  data-aos-duration="700"
                  data-aos="fade-left"
                  className="title_4 d-grid"
                >
                  <h3
                    style={{
                      fontSize: "36px",
                      lineHeight: "1.4",
                      marginTop: "20px",
                    }}
                  >
                    Why Methane Pyrolysis?
                  </h3>{" "}
                  {/* Added marginTop */}
                  <p style={{ marginBottom: "20px" }}>
                    {" "}
                    {/* Added marginBottom */}
                    Methane pyrolysis is a process that uses power to heat
                    methane and thermally crack it into hydrogen and a solid
                    carbon co-product. Depending on the reaction temperature and
                    catalysts used in the process, the solid carbon can vary in
                    its composition and uses, ranging from construction
                    materials, carbon black for tires, or graphite for battery
                    production.
                  </p>
                  <p>
                    The clean hydrogen produced can be used directly to
                    decarbonize industrial processes or for use in heavy-duty
                    transport. The challenge is that unless the end-user is
                    co-located or there is a dedicated hydrogen pipeline nearby,
                    it is difficult to get the hydrogen to them for use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Renewable Power + Clean Hydrogen Section */}
        <div className="power-generation-info-wrap my-5">
          <div className="box_content">
            <div className="row gx-4 gy-5">
              {/* Text Section */}
              <div className="col-xl-6 col-sm-12 col-12 d-flex align-items-center">
                <div
                  data-aos-duration="700"
                  data-aos="fade-right"
                  className="title_4 d-grid"
                >
                  <h3 style={{ fontSize: "36px", lineHeight: "1.4" }}>
                    Renewable Power + Clean Hydrogen + Biogenic CO2 = Clean
                    Fuels
                  </h3>
                  <p>
                    Clean hydrogen will play in our decarbonization journey in
                    industrial applications that cannot be electrified, but
                    there is an increasing realization that many of the uses of
                    hydrogen that have been promoted really do not make
                    technical or economic sense. The good news is that shifts
                    the focus to uses that do make sense: clean fuels.
                  </p>
                  <br />
                  <p>
                    The next step in the clean fuels process is to use
                    geothermal power, clean hydrogen, and CO2 to produce
                    low-carbon methanol. The CO2 used in the process is either
                    captured directly from the air or from a biogenic source.
                    Biogenic CO2 comes from separating the carbon that has
                    already been captured by plants through photosynthesis. This
                    is the logical near-term pathway while the direct-air
                    capture (DAC) matures to be economically viable.
                  </p>
                  <br />
                </div>
              </div>
              {/* Image Section */}
              <div
                data-aos-duration="700"
                data-aos="fade-left"
                className="col-xl-6 col-sm-12 col-12"
              >
                <div className="d-flex justify-content-center">
                  {" "}
                  {/* Centering the image */}
                  <img
                    src={methanolImage}
                    alt=""
                    className="bottom-dashbord-img img-fluid"
                    style={{ height: "auto", width: "500px", maxWidth: "100%" }}
                  />
                </div>
              </div>
              <div className="title_4">
                {/* Additional Text */}
                <p>
                  Methanol can be used directly as a fuel in shipping and
                  heavy-duty transport. While methanol is itself a very simple
                  molecule, it is the building block to create more complex
                  fuels such as jet fuel and gasoline. Through this process, we
                  are not adding additional fossil-based carbon to the
                  atmosphere when these fuels are used
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Footer or other sections */}
      </div>
    </>
  );
});

export default DashboardPage;
