import React from "react";
import { Button } from "react-bootstrap";

const BannerContatctUs = (props) => {
  // const navigate = useNavigation()
  return (
    <div className="banner-wrap">
      {/* <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1100"> */}
      <video
        src={
          // "https://res.cloudinary.com/df6bctdlx/video/upload/v1713873260/geofuels/mluublngpfkmswfbprm2.mp4"
          // "https://all-sites-under.s3.us-east-2.amazonaws.com/hydrogen196/h2_homepagevideo.mov"
        "https://all-sites-under.s3.us-east-2.amazonaws.com/geothermal/geothermal-360-movies.mp4"
        }
        loop
        muted
        autoPlay
        className="banner-video"
      />
      {/* </div> */}

      {/* <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1200">
        <img src={banner} alt="" className="banner-svg" />
      </div> */}

      <div className="banner-content-contact">
        <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1300">
          <div className="row">
            <div className="nik">
              <div className=" opacity-100  banner-text-top">
                <p className="opacity-75">Embracing the future of Clean Fuels</p>
                <h5 className="banner-text text-white" style={{ fontSize: '36px' , lineHeight: '1.4' , padding: '16px 0px'}}>
                We are developing a new, cleaner pathway to Hydrogen and Liquid Fuels
                </h5>
                <h6 className="text-white" style={{ lineHeight: '1.4' }}>
                As the world grapples with how to meet its ever-increasing energy needs while reducing emissions, one thing is clear:  the need for liquid fuels is not going away.  But how those fuels are produced will have an impact.  It is possible to create fuels that do not emit additional CO2 into the atmosphere, and that is our purpose.
                </h6>
                <div className="pt-2 d-flex flex-row ">
                  <Button
                    onClick={props.onLearnMore}
                    className="btn learn-more  me-2 text-white"
                    title="Learn more"
                    // style={{borderRadius:'30px'}}
                  >
                    Know more
                  </Button>{" "}
                  &nbsp;
                  <Button
                    onClick={props.onLets}
                    className="btn btn-outline-success transparent-bg text-white"
                    title="Let's connect"
                  >
                    Let's connect
                  </Button>
                </div>
              </div>
            </div>
            <div className=" col-6" />
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default BannerContatctUs;
