import React, { useEffect, useState } from "react";
import InnerBanner from "../innerBanner";
import { Col, FormControl, FormLabel, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import contactBanner from "../../assets/img/greenHydroImages/contactusBanner.png";
import Loader from "../layout/loader";
import authService from "../services/authService";

const ContactUs = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const onChangeEvent = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  // console.log(data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (!data.firstName || !data.lastName || !data.email || !data.phone) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter mandatory fields!",
      });
      return;
    }

    // Compose the email subject and body
    const emailSubject = data.subject || "Contact Form Submission";
    const emailBody = `
      First Name: ${data.firstName}
      Last Name: ${data.lastName}
      Email: ${data.email}
      Phone: ${data.phone}
      Subject: ${data.subject}
      Message: ${data.message}
    `;

    // Encode email addresses, subject, and body
    const encodedTo = encodeURIComponent("jay@geofuels.com");
    const encodedCC = encodeURIComponent("jay@edge196.com");
    const encodedSubject = encodeURIComponent(emailSubject);
    const encodedBody = encodeURIComponent(emailBody);

    // Compose the mailto link without semicolons
    const mailtoLink = `mailto:${encodedTo}?cc=${encodedCC}&subject=${encodedSubject}&body=${encodedBody}`;

    // Open the default email client
    window.open(mailtoLink, "_blank");

    // Reset the form fields
    setData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });

    // Show success message
    Swal.fire({
      icon: "success",
      title: "Submitted",
      text: "All the details are collected. We will contact you soon.",
    });
  };

  // let handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!data.firstName || !data.lastName || !data.email || !data.phone) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: "Please enter mandatory field!",
  //     });
  //     return;
  //   }
  //   let dataDetails = {
  //     firstName: data.firstName,
  //     lasttName: data.lastName,
  //     email: data.email,
  //     phone: data.phone,
  //     subject: data.subject,
  //     message: data.message,
  //   };
  //   // console.log(dataDetails);

  //   let emailBody = `Name: ${data.firstName} ${data.lastName}\n`;
  //   emailBody += `Email: ${data.email}\n`;
  //   emailBody += `Phone: ${data.phone}\n`;
  //   emailBody += `Subject: ${data.subject}\n\n`;
  //   emailBody += `Message: ${data.message}`;

  //   try {
  //     await authService.contactDetails(dataDetails).then((res) => {
  //       if (res.status) {
  //         document.getElementById("create-course-form").reset();

  //         window.location.href = `mailto:jay@geofuels.com?cc=jay@edge196.com&subject=${encodeURIComponent(
  //           data.subject
  //         )}&body=${encodeURIComponent(emailBody)}`;

  //         Swal.fire({
  //           icon: "success",
  //           title: "Submitted",
  //           text: "All the details are collected. We will contact you soon.",
  //         });
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error",
  //           text: "Some error occured. Please try again leter.",
  //         });
  //       }
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  return (
    <>
      <Loader />
      <InnerBanner title="CONTACT US" image={contactBanner} />
      <div className="">
        {/* <div className="container_inner"> */}
        <div className="contact-form">
          <div className="row">
            <div className="col-xl-5 col-sm-12 col-12 address_info_heading">
              <div className="contact_heading">
                <h3>Contact Information</h3>
              </div>
              <br />
              <br />
              <div className="contact_address">
                <h5>Address</h5>
                <p>
                  2150 Town Square Place, Suite 200, <br />
                  Sugar Land, Texas 77479
                </p>
              </div>
              <br />
              <br />
              <div className="contact_Investors">
                <h3>Investor's Relation</h3>
              </div>
              <div className="contact_email">
                <h5>Jay Patel</h5>
                <p>Chairman</p>
                <p>
                  <a href="mailto:jay@geofuels.com"> jay@geofuels.com</a>{" "}
                </p>
              </div>
              <br />

              <div className="contact_Investors_details">
                <h5>Mark Viehman</h5>
                <p>Co-Founder</p>
                <p>
                  {" "}
                  <a href="mailto:mark@geofuels.com">mark@geofuels.com</a>{" "}
                </p>
                {/* <h5>Dr. Michael Shoemaker</h5>
                <p>COO</p>
                <p>mshoemaker@hydrogen196.com</p> */}
              </div>
            </div>

            {/* <div className="col-1"></div> */}
            <div className="col-xl-7 col-sm-12 col-12 mt-xl-0 mt-4">
              <div className="contact-field">
                <div className="row">
                  <div className="conatct-form-sumbit">
                    <h3>Get in touch with us</h3>
                    <div className="form-wrap">
                      <form onSubmit={handleSubmit} id="create-course-form">
                        <Row className="g-4">
                          <Col md="6">
                            <FormLabel>First Name</FormLabel>
                            <FormControl
                              placeholder="John"
                              name="firstName"
                              value={data.firstName}
                              onChange={onChangeEvent}
                              required
                            />
                          </Col>

                          <Col md="6">
                            <FormLabel>Last Name</FormLabel>
                            <FormControl
                              placeholder="Doe"
                              value={data.lastName}
                              name="lastName"
                              onChange={onChangeEvent}
                              required
                            />
                          </Col>

                          <Col md="6">
                            <FormLabel>Email Address</FormLabel>
                            <FormControl
                              type="email"
                              value={data.email}
                              placeholder="johndoe@email.com"
                              onChange={onChangeEvent}
                              name="email"
                              required
                            />
                          </Col>

                          <Col md="6">
                            <FormLabel>Phone number</FormLabel>
                            <FormControl
                              type="number"
                              value={data.phone}
                              placeholder="000 000 0000"
                              onChange={onChangeEvent}
                              name="phone"
                              required
                            />
                          </Col>

                          <Col md="12">
                            <FormLabel>Subject</FormLabel>
                            <FormControl
                              value={data.subject}
                              placeholder="Enter your subject for the inquiry"
                              onChange={onChangeEvent}
                              name="subject"
                            />
                          </Col>

                          <Col md="12">
                            <FormLabel>Message</FormLabel>
                            <FormControl
                              as="textarea"
                              value={data.message}
                              placeholder="Type you message here"
                              onChange={onChangeEvent}
                              rows={6}
                              name="message"
                            />
                          </Col>
                          <Col md="12">
                            <button type="submit" className="btn submit-btn">
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="media-contact">
              <div className="row">
                <div className="col-xl-3 col-md-12 col-sm-12 col-12">
                  <div className="media-heading">
                    <h3>For Media Inquiry</h3>
                  </div>
                </div>
                <div className="col-xl-9 col-md-12 col-sm-12 col-12">
                  <div className="row media-contact-details">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      <h3>Shane Quimby</h3>
                      <p>Head of Communications & Public Affairs</p>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 media-head-subtitle">
                      <h3>Phone</h3>
                      <p>832-493-8172</p>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 media-head-subtitle">
                      <h3>Email</h3>
                      <p>squimby@hydrogen196.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default ContactUs;
