import React, { useEffect, useState } from "react";
// import { Col, Row } from "react-bootstrap";
import InnerBanner from "../innerBanner";
import Team from "./team";

// import Team from "../team";
// import jay from "../../assets/img/team/jayy.png";
// import andy from "../../assets/img/team/Andrew.png";
import Aos from "aos";
import bottomgrouprightone from "../../assets/img/AboutUsRightOne.png";
import rightimage from "../../assets/img/AboutusImgOne.png";
import bannerImage from "../../assets/img/Group 13218.png";
import Loader from "../layout/loader";

import aboutBanner from "../../assets/img/greenHydroImages/inner-banner.png";
import { height } from "@fortawesome/free-brands-svg-icons/fa42Group";

const AboutUs = (props) => {
  const [teamFilter, setTeamFilter] = useState(Team);
  const [active, setActive] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init();
  }, []);

  let filterTeam = [];

  const handleFilter = (val) => {
    setActive(val);
    setTeamFilter(Team);
  };

  const handleFilterFounder = (val) => {
    setActive(val);
    filterTeam = Team.filter((res) => {
      if (res.designation === "CTO" || res.designation === "CEO") {
        return res;
      }
    });
    setTeamFilter(filterTeam);
  };

  const handleFilterOperations = (val) => {
    setActive(val);
    filterTeam = Team.filter((res) => {
      if (res.designation === "Operations") {
        return res;
      }
    });
    setTeamFilter(filterTeam);
  };

  const handleFilterBusiness = (val) => {
    setActive(val);
    filterTeam = Team.filter((res) => {
      if (res.designation === "Business") {
        return res;
      }
    });
    setTeamFilter(filterTeam);
  };

  const handleFilterFinance = (val) => {
    setActive(val);
    filterTeam = Team.filter((res) => {
      if (res.designation === "Financial " || res.designation === "Finance") {
        return res;
      }
    });
    setTeamFilter(filterTeam);
  };

  // const handleFilterTech = (val) => {
  //   setActive(val);
  //   filterTeam = Team.filter((res) => {
  //     if (
  //       res.position === "Chief Engineering Officer" ||
  //       res.designation === "CTO"
  //     ) {
  //       return res;
  //     }
  //   });
  //   setTeamFilter(filterTeam);
  // };

  const handleFilterLegal = (val) => {
    setActive(val);
    filterTeam = Team.filter((res) => {
      return res.designation === "Legal";
    });
    console.log(filterTeam);
    setTeamFilter(filterTeam);
  };

  return (
    <>
      <Loader />
      <InnerBanner
        data-aos-duration="700"
        data-aos="fade-up"
        data-aos-delay="1000"
        title="About Us"
        image={aboutBanner}
      />
      <div className="middle_section_aboutus">
        <div className="banner_section">
          <div className="">
            <div className="row position-relative">
              <div
                data-aos-duration="700"
                data-aos="fade-up"
                data-aos-delay="1000"
                className="col-12"
              >
                <div className="banner_Image_about position-relative">
                  <img src={bannerImage} alt="" className="img-fluid w-100" />
                  <div className="banner_text position-absolute top-50 start-0 translate-middle-y w-80">
                    <h3>Our Mission</h3>
                    <p>
                      Our mission is to produce sustainable fuels leveraging
                      geothermal energy and methane in a carbon-neutral manner.
                      These fuels can be economically produced and help add
                      resilience to energy supply chains.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="bottom_text_section">
          <div className="">
            <div className="row">
              <div
                data-aos-duration="700"
                data-aos="fade-left"
                data-aos-delay="1000"
                className="col-lg-6 col-sm-12 col-12"
              >
                <h3>
                  {/* <br /> <br /> */}
                  Vision: To lead the transition towards carbon-neutral fuels
                  production.
                </h3>
                <p>
                  <br />
                  By vertically integrating across the relevant value chain,
                  from geothermal power to clean hydrogen and derivatives such
                  as e-methanol, e-gasoline and sustainable aviation fuels, we
                  are able to maximize the value of this energy by sending it to
                  the markets that value it the most.
                </p>
                <p>
                  <br></br>
                  This vertical integration also offers unique opportunities for
                  thermal integration of production processes, further driving
                  down costs. Rather than waste process heat, it is recirculated
                  back to produce even more power.
                </p>
              </div>
              <div className="col-lg-6 col-sm-12 col-12">
                <div
                  data-aos-duration="700"
                  data-aos="fade-right"
                  data-aos-delay="1000"
                  className="img-about-us d-flex justify-content-end"
                >
                  <img src={bottomgrouprightone} alt="" className="w-75"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="bottom-section-about">
            <div className="about-us-mission">
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 col-12 ">
                  <div
                    data-aos-duration="700"
                    data-aos="fade-left"
                    className="text_area_"
                  >
                    <h3
                      style={{
                        lineHeight: "1.4",
                        fontSize: "28px",
                        marginBottom: "20px",
                      }}
                    >
                      The US Gulf Coast is our home and our starting point, and
                      then we plan to then deploy globally, where the right
                      resources are available.
                    </h3>
                    <p>
                      Sustainble fuels enhance energy security by reducing
                      dependence on imported fossil fuels, allowing countries to
                      produce energy locally from renewable sources.
                      Additionally, sustainable fuels promote the development of
                      sustainable energy infrastructure, creating a resilient
                      and cleaner energy system for the future.
                    </p>
                    <div className="bottom_section_p">
                      <p>
                        At GeoFuels, we're revolutionizing a sustainable future.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-aos-duration="700"
                  data-aos="fade-right"
                  className="col-lg-7 col-md-12 col-sm-12 col-12 mt-3 mt-lg-0  d-flex justify-content-end"
                >
                  <img src={rightimage} alt="" className="w-75"></img>
                </div>
              </div>
            </div>
            <div className="row team-filter-head">
              <div className="col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12 ">
                    <p className="p-1">Our Team</p>
                  </div>
                  <div className="col-lg-8 col-md-12 col-sm-12 col-12 filter-team">
                    <div className="p-1">
                      <button
                        onClick={(active) => handleFilter(1)}
                        className={
                          active === 1
                            ? "btn btn-success  rounded-0"
                            : "btn btn-outline-success  rounded-0"
                        }
                      >
                        {" "}
                        All{" "}
                      </button>
                    </div>
                    <div className="p-1">
                      <button
                        onClick={(active) => handleFilterFounder(2)}
                        className={
                          active === 2
                            ? "btn btn-success  rounded-0"
                            : "btn btn-outline-success  rounded-0"
                        }
                      >
                        {" "}
                        Founder{" "}
                      </button>
                    </div>
                    <div className="p-1">
                      <button
                        onClick={() => handleFilterOperations(3)}
                        className={
                          active === 3
                            ? "btn btn-success  rounded-0"
                            : "btn btn-outline-success  rounded-0"
                        }
                      >
                        {" "}
                        Operations{" "}
                      </button>
                    </div>
                    <div className="p-1">
                      <button
                        onClick={() => handleFilterBusiness(4)}
                        className={
                          active === 4
                            ? "btn btn-success  rounded-0"
                            : "btn btn-outline-success  rounded-0"
                        }
                      >
                        {" "}
                        Business{" "}
                      </button>
                    </div>
                    <div className="p-1">
                      <button
                        onClick={() => handleFilterFinance(5)}
                        className={
                          active === 5
                            ? "btn btn-success  rounded-0"
                            : "btn btn-outline-success  rounded-0"
                        }
                      >
                        {" "}
                        Finance{" "}
                      </button>
                    </div>
                    {/* <div className="p-1">
                      <button
                        onClick={() => handleFilterTech(6)}
                        className={
                          active === 6
                            ? "btn btn-success  rounded-0"
                            : "btn btn-outline-success  rounded-0"
                        }
                      >
                        {" "}
                        Technology{" "}
                      </button>
                    </div> */}
                    <div className="p-1">
                      <button
                        onClick={() => handleFilterLegal(6)}
                        className={
                          active === 6
                            ? "btn btn-success  rounded-0"
                            : "btn btn-outline-success  rounded-0"
                        }
                      >
                        {" "}
                        Legal{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 mt-2">
                <div className="row g-4">
                  {teamFilter.map((el) => (
                    <div
                      key={el.id}
                      className="col-sm-6 col-md-4 col-lg-3 col-xl-2"
                    >
                      <div
                        data-aos-duration="700"
                        data-aos="fade-up"
                        data-aos-delay="1000"
                        className="team_img"
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "100%",
                            maxWidth: "260px",
                            margin: "0 auto",
                          }}
                        >
                          <img
                            src={el.img_path}
                            alt={el.name}
                            style={{
                              width: "100%",
                              height: "300px",
                              objectFit: "cover",
                            }}
                          />
                          <div
                            className="team_text"
                            style={{
                              width: "100%",
                              textAlign: "left",
                              // marginTop: '10px',
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                fontWeight: "bold",
                                fontSize: "18px",
                              }}
                            >
                              {el.name}
                            </p>
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#666",
                              }}
                            >
                              {el.position}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
